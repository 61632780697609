<template>
    <div class="px-3 md:px-0 pt-4">

        <p class="my-4 text-xl leading-8 font-semibold tracking-tight text-gray-900 capitalize">
            Modul Saya
        </p>
        
        <router-link :to="{
            name : 'detailModul',
            params : {
                'singkatan_kategori': data.kategori.singkatan.toLowerCase(),
                'tipe_modul'        : data.status_bayar_label,
                'id_paket'          : data.id_paket
            }
        }" class="flex items-center bg-white py-2 px-3 shadow-md rounded-lg my-2 transition-colors hover:border-gray-300"
        v-for="(data, index) in modulList" :key="index">
            <div class="w-16">
                <div class="h-16 w-16 bg-yellow-100 rounded-full content-center flex items-center text-center">
                    <img src="../../assets/images/package.svg" class="h-10 w-10 mx-auto"/>
                </div>
            </div>
            <div class="flex-grow ml-3 pt-1 pb-1">
                <h2 class="font-semibold tracking-tight text-gray-800 text-xl">{{ data.nama_paket }}</h2>
                <span class="mr-5 text-gray-700 text-based">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block -mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                    <span v-if="data.harga_paket == null">
                        Gratis
                    </span>
                    <span v-else>
                        Rp. {{ data.harga_paket | moneyFormat }}
                    </span>
                </span>
                <span class="mr-2 text-gray-700 text-based">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block -mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                    </svg>
                    {{ data.kuis_count }} Kuis
                </span>
            </div>
            <div class="p-3">
                <svg v-if="data.status_register == 1" xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                </svg>
                <svg v-if="data.status_register == 0" xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>
        </router-link>

        <div class="animate-pulse" v-if="isLoadingPaket">
            <div class="flex bg-white py-2 px-3 border rounded-md my-2 transition-colors" v-for="i in 3" :key="i">
                <div class="h-16 w-16 bg-gray-100 rounded-lg content-center flex items-center text-center">
                </div>
                <div class="flex-grow ml-3 pt-1 pb-1">
                    <div class="h-6 bg-gray-100 rounded"></div>
                    <div class="h-5 w-32 mt-2 inline-block mr-2 bg-gray-100 rounded"></div>
                    <div class="h-5 w-40 mt-2 inline-block bg-gray-100 rounded"></div>
                </div>
            </div>
        </div>

   </div>
</template>
<script>
    import { combineObject, errorHandler, showErrorNotif } from '../../helpers/Tools';
    export default {
        name: 'ModulUser',
        data(){
            return {
                isLoading     : true,
                isLoadingPaket: true,
                page          : 1,
                modulList     : [],
                isEmpty       : false
            }
        },
        mounted() {
			this.getUserPaket();
            this.scroll();
		},
        destroyed() {
            window.onscroll = () => {
            }
        },
		methods : {
            scroll() {
                window.onscroll = () => {
                    const bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
                    if (bottomOfWindow && !this.isEmpty) {
                        this.getUserPaket()
                    }
                }
            },
            async getUserPaket() {
                this.isLoadingPaket = true;
                try {
                    let res = await this.$store.dispatch("getPaketJoinedByUser", {
                        page : this.page,
                    });
                    this.isLoadingPaket = false;
                    let result = res.data;
                    if(result.status == 'success') {
                        if(result.data.data.length == 0) {
                            this.isEmpty = true;
                        }

                        if(this.page == 1){
                            this.modulList = result.data.data;
                        }else{
                            this.modulList = combineObject(this.modulList, result.data.data, 'id_paket')
                        }
                        this.page++;

                    } else {
                        this.isEmpty = true;
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    this.isLoadingPaket = false;
                    errorHandler(this, error);
                }
            }
        }
    }
</script>
